<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <v-row>
          <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        </v-row>
        <v-row>
          <v-col cols="9">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="3">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfiguracionTablasFacturacion()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de período</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="openInputPeriodo()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <v-col cols="4" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Vigentes al"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigencia = parseDateToIso(
                              fechaVigenciaSelected
                            )
                          "
                          v-on="on"
                          :rules="
                            fechaVigencia != null
                              ? rules.validDate
                              : rules.required
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="
                          fechaVigenciaSelected = formatDate(fechaVigencia)
                        "
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="agente-autocomplete"
                      v-model.trim="agenteSelected"
                      :items="agentes"
                      :search-input.sync="agentesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :filter="customFilterAgente"
                      outlined
                      clearable
                      hide-no-data
                      hint="Ingrese código o nombre de agente"
                      return-object
                      dense
                      :cache-items="banderaTrue ? banderaTrue : !banderaTrue"
                      autocomplete="off"
                      label="Agente de pago"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="agentesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Origen -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="origen-autocomplete"
                      v-model.trim="origenSelected"
                      :items="origenes"
                      :search-input.sync="origenesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="origenChange(origenSelected)"
                      :filter="customFilterOrigen"
                      outlined
                      hide-no-data
                      hint="Ingrese código o nombre de orígen"
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Orígen"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="origenesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de comprobante -->
                </v-row>
                <v-row>
                  <!-- Codigo -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      class="theme--light.v-input input"
                      label="Nombre de tabla"
                      v-model="nombreTabla"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Prestador -->
                  <v-col cols="6" md="5" class="py-0">
                    <v-autocomplete
                      ref="prestadores-autocomplete"
                      v-model.trim="prestadoresSelected"
                      :items="prestadores"
                      :search-input.sync="prestadoresAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="prestadorChange(prestadoresSelected.id)"
                      :filter="customFilterPrestadores"
                      outlined
                      hide-no-data
                      hint="Ingrese matrícula o nombre del prestador"
                      clearable
                      cache-items
                      return-object
                      dense
                      autocomplete="off"
                      label="Prestador"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="prestadoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-1 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="1"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card class="mb-4">
          <v-data-table
            :headers="headers"
            item-key="tablaId"
            v-model="registroSelected"
            :items="itemsNuevaTablaFac"
            show-select
            show-expand
            :expanded.sync="expanded"
            :items-per-page="20"
            :footer-props="{
              'items-per-page-options': [20, 40, 60, 80]
            }"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
            @toggle-select-all="selectAllToggle"
          >
            <template>
              <v-icon v-if="registroSelected.length > 0">
                mdi-checkbox-marked</v-icon
              >
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.nuevoNombre`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  {{ item.nuevoNombre }}
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="'primary'"
                    size="16"
                    @click="openModal(item)"
                    >{{ editIcon }}</v-icon
                  >
                </template>
                <span>Editar valor</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="verProceso(item, item.ctaId)"
                  >
                    {{ verProc }}
                  </v-icon>
                </template>
                <span>Ver tabla de facturación</span>
                <!-- cambiar por ver -->
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{ isExpanded ? chevronUpIcon : chevronDownIcon }}</v-icon
                  >
                </template>
                <span>Ver observaciones</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="12">
                    <strong>Observaciones:</strong>
                    {{ item.observaciones }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-col cols="12" class="text-right py-1">
          <v-tooltip top max-width="35%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                align="end"
                class="m-2"
                color="primary"
                v-bind="attrs"
                outlined
                :disabled="itemsNuevaTablaFac.length < 1"
                @click="exportExcelModelo"
              >
                Exportar lista completa
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-card>
          <v-form
            v-model="actualizacionValida"
            ref="actualizacion-form"
            id="actualizacion-form"
            @submit.prevent="aplicarActualizacion()"
          >
            <v-card>
              <v-col cols="12" class="py-0">
                <v-card-title class="primary--text pb-0">
                  Datos para actualización de tablas</v-card-title
                >
              </v-col>
              <v-row class="m-3">
                <!-- % importe fijo -->
                <v-col cols="2" md="3" class="py-0">
                  <v-text-field
                    v-model="porcentajeImporte"
                    :append-icon="porcentajeIcon"
                    hint="Porcentaje importe fijo"
                    dense
                    onkeydown="return (event.keyCode !== 69 &&
                        event.keyCode !== 109 &&
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                    type="number"
                    :rules="rules.required.concat(rules.decimalMil)"
                    label="Porcentaje importe fijo"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <!-- % unidades -->
                <v-col cols="2" md="3" class="py-0">
                  <v-text-field
                    v-model="porcentajeUnidades"
                    :append-icon="porcentajeIcon"
                    hint="Porcentaje unidades"
                    dense
                    onkeydown="return (event.keyCode !== 69 &&
                        event.keyCode !== 109 &&
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                    type="number"
                    :rules="rules.decimalMil"
                    label="Porcentaje unidades"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="3" class="py-0">
                  <v-menu
                    v-model="menuFechaDesde"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaSelectedDesde"
                        label="Ingrese fecha"
                        :append-icon="calendarIcon"
                        v-bind="attrs"
                        autocomplete="not"
                        clearable
                        hint="Formato DD/MM/AAAA"
                        @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                        :rules="rules.required.concat(rules.validDate)"
                        outlined
                        dense
                        v-mask="'##/##/####'"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaDesde"
                      no-title
                      @change="fechaSelectedDesde = formatDate(fechaDesde)"
                      @input="menuFechaDesde = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1" class="pa-0 mt-2">
                  <v-tooltip right max-width="17%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      Fecha de inicio de vigencia de la tabla nueva
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="3"
                  sm="6"
                  md="3"
                  class="py-0 my-0 d-flex justify-center"
                >
                  <v-autocomplete
                    v-model="tipoRedondeoSelected"
                    :items="tipoRedondeo"
                    return-object
                    :rules="rules.required"
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                    autocomplete="off"
                    label="Tipo de redondeo"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="1" class="pa-0 mt-2">
                  <v-tooltip right max-width="17%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>Aplica solo a los importes</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-right py-1">
                  <v-btn
                    align="end"
                    class="m-2"
                    color="primary"
                    elevation="2"
                    :disabled="!actualizacionValida"
                    :loading="loadingBtn"
                    type="submit"
                    form="actualizacion-form"
                  >
                    Procesar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-12 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalPeriodo"
      v-model="openModalPeriodo"
      @keydown.esc="closeModalPeriodo"
      max-width="45%"
      persistent
    >
      <v-card class="mx-auto 0" max-width="700" outlined>
        <v-card-title class="headline primary--text">
          Periodo para nombre de nueva tabla
        </v-card-title>
        <v-form
          v-model="isFormValidPeriod"
          ref="periodo-form"
          id="periodo-form"
          @submit.prevent="
            newSuffixTabla == null || newSuffixTabla.length === 0
              ? (openConfirmDialog = true)
              : applyFilters()
          "
        >
          <v-row class="m-3">
            <v-col cols="8" md="4" class="py-0">
              <v-text-field
                v-model="newSuffixTabla"
                hint="Periodo nombre de tabla"
                dense
                :rules="
                  pidePeriodo
                    ? rules.required.concat(rules.formatoPeriodoName)
                    : rules.formatoPeriodoName
                "
                v-mask="'######'"
                label="Periodo"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="py-0 pa-0 mt-2">
              <v-tooltip right max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  Se recomienda usar períodos numéricos AAAAMM o MMAAAA
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-card-actions class="py-0 m-3">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="
                openModalPeriodo = false;
                newSuffixTabla = null;
              "
            >
              Cancelar
            </v-btn>
            <v-btn
              type="submit"
              :disabled="!isFormValidPeriod"
              form="periodo-form"
              color="primary"
              :loading="loadingBtnSave"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalVerProceso"
      v-model="modalVerProceso"
      @keydown.esc="closeModalProceso"
      max-width="65%"
      persistent
    >
      <VerProcesoTablaFac
        :ctaId="ctaId"
        :tablaId="tablaId"
        :tablaNombre="tablaNombre"
        @closeModalProceso="modalVerProceso = false"
      ></VerProcesoTablaFac>
    </v-dialog>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="550px"
      @keydown.enter="saveEditValor"
      persistent
      @keydown.esc="openModalEdit = false"
    >
      <v-form
        v-model="isFormValidPeriod"
        ref="nombre-tabla-form"
        id="nombre-tabla-form"
        @submit.prevent="saveEditValor()"
      >
        <v-card>
          <v-container>
            <v-card-title class="headline primary--text">
              Editar nuevo nombre de tabla
            </v-card-title>
            <v-card-text class="py-0">
              <v-text-field
                v-model="editNuevoNombre"
                :rules="
                  rules.required.concat(rules.maxLength(editNuevoNombre, 93))
                "
                label="Nuevo nombre de tabla"
                dense
                outlined
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn outlined @click="openModalEdit = false">
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :disabled="!isFormValidPeriod"
                form="nombre-tabla-form"
                :loading="loadingBtnSave"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @onConfirm="applyFilters()"
        :submitBtn="'SI'"
        :cancelBtn="'NO'"
      />
    </v-card>
    <v-card>
      <ConfirmDialog
        max-width="800px"
        :text="textConfirmDialogExcelError"
        :openConfirm.sync="openConfirmDialogExcelError"
        @onConfirm="exportarExcelError()"
      />
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import VerProcesoTablaFac from "@/components/modules/prestadores/VerProcesoTablaFac.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "NuevaActualizacionTablaFacturacion",
  components: {
    PageHeader,
    Ayuda,
    FiltersSelected,
    VerProcesoTablaFac,
    ConfirmDialog,
    GoBackBtn
  },
  directives: { mask },
  data() {
    return {
      tipoRedondeo: [
        {
          id: 0,
          value: "Sin redondeo"
        },
        {
          id: 1,
          value: "Unidad"
        },
        {
          id: 2,
          value: "Decena"
        }
      ],
      tipoRedondeoSelected: null,
      optionCode: enums.webSiteOptions.CONSULTA_TABLA_FAC,
      settingsIcon: enums.icons.SETTINGS,
      banderaTrue: false,
      textConfirmDialog:
        "¿Desea salir sin agregar período al título de la nueva tabla?",
      openConfirmDialog: false,
      newSuffixTabla: "",
      expanded: [],
      menuFechaVigencia: null,
      registroSelected: [],
      fechaVigencia: null,
      fechaVigenciaSelected: null,
      infoIcon: enums.icons.SNB_INFO,
      origenSelected: null,
      itemsOrigen: [],
      itemsNuevaTablaFac: [],
      prestadoresSelected: null,
      prestadores: [],
      banderaPrestadorselected: false,
      prestadoresAutocompleteInput: null,
      prestadoresLoading: false,
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      percentOptions: {
        locale: "es-ES",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: -99.99,
          max: 10000
        },
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: false,
        accountingSign: false
      },
      actualizacionValida: false,
      porcentajeImporte: null,
      porcentajeUnidades: null,
      nombreTabla: "",
      title: enums.titles.NUEVA_ACT_TABLA_FACTU,
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      fechaSelectedDesde: null,
      routeToGo: "ActualizacionTablasFacturacion",
      verProc: enums.icons.SEE,
      menuFechaDesde: false,
      fechaDesde: null,
      rules: rules,
      showExpand: false,
      showHelp: false,
      showFilters: true,
      filtersApplied: [],
      isFormValid: false,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      loading: false,
      agenteSelected: null,
      agentes: [],
      agentesAutocompleteInput: null,
      agentesLoading: false,
      banderaOrigenSelected: false,
      banderaAgentesSelected: false,
      origenesAutocompleteInput: null,
      search: "",
      infoNuevaActualizacion: null,
      showIcon: true,
      modalVerDetalle: false,
      modalVerProceso: false,
      loadingBtn: false,
      openModalPeriodo: false,
      origenes: [],
      origenesLoading: false,
      headers: [
        {
          text: "Nombre tabla",
          value: "tablaNombre",
          align: "start",
          sortable: false
        },
        {
          text: "Observaciones",
          value: "observaciones",
          sortable: false,
          align: "start"
        },
        {
          text: "Nuevo nombre tabla",
          value: "nuevoNombre",
          sortable: false,
          align: "start"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      openModalEdit: false,
      idToEdit: null,
      editIcon: enums.icons.EDIT,
      editNuevoNombre: null,
      pidePeriodo: null,
      isFormValidPeriod: false,
      loadingBtnSave: false,
      tablasErrores: null,
      textConfirmDialogExcelError:
        "La actualización posee errores, ¿desea exportar el detalle a Excel?",
      openConfirmDialogExcelError: false
    };
  },
  watch: {
    prestadoresAutocompleteInput(val) {
      if (val && val.length > 2) {
        this.getPrestadoresFilter();
      } else {
        this.$refs["prestadores-autocomplete"].cachedItems = [];
      }
    },
    origenesAutocompleteInput(val) {
      if (val) {
        this.getOrigenesFilter();
      }
    },
    agentesAutocompleteInput(val) {
      if (val) {
        this.getAgentesFilter();
        this.banderaTrue = true;
      } else {
        this.$refs["agente-autocomplete"].cachedItems = [];
      }
    }
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  methods: {
    ...mapActions({
      getAgentePagoByQuery: "prestadores/getAgentePagoByQuery",
      fetchOrigenesNombreByQuery: "prestadores/fetchOrigenesNombreByQuery",
      fetchTablasFacturacionByFilters:
        "prestadores/fetchTablasFacturacionByFilters",
      getParametrosGeneralesTablasFac:
        "prestadores/getParametrosGeneralesTablasFac",
      saveProcesoAumentoTablasFacturacion:
        "prestadores/saveProcesoAumentoTablasFacturacion",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresByQuery",
      //fetchOrigenesByQuery: "prestadores/fetchOrigenesByQuery",
      setAlert: "user/setAlert"
    }),
    async openInputPeriodo() {
      this.openModalPeriodo = true;
      this.loadingBtnSave = true;
      const response = await this.getParametrosGeneralesTablasFac();
      this.pidePeriodo = response.pidePeriodo;
      if (response) {
        this.loadingBtnSave = false;
      }
    },
    // metodos de filtros
    async applyFilters() {
      this.openModalPeriodo = false;
      this.showFilters = false;
      this.loading = true;
      this.customizeFiltersApplied();
      const filters = {
        agenteId: this.agenteSelected ? parseInt(this.agenteSelected.id) : null,
        oriId: this.origenSelected != null ? this.origenSelected.id : null,
        vigencia: this.parseDateToIso(this.fechaVigenciaSelected),
        nombreTabla: this.nombreTabla,
        prestador: this.prestadoresSelected,
        periodoNombreTabla: this.newSuffixTabla ? this.newSuffixTabla : null
      };
      try {
        // const data = await this.getParametrosGeneralesTablasFac(filters)
        const response = await this.fetchTablasFacturacionByFilters(filters);
        this.itemsNuevaTablaFac = response.data.data;
        this.registroSelected = response.data.data;
        this.loading = false;
        this.showFilters = false;
      } catch {
        this.loading = false;
      }
    },
    async aplicarActualizacion() {
      this.loadingBtn = true;
      const seleccionados = this.registroSelected.find(item => item.tablaId);
      const existenRepetidos = this.registroSelected.some(x =>
        this.registroSelected.some(
          y => y.nuevoNombre === x.nuevoNombre && x.tablaId !== y.tablaId
        )
      );
      if (seleccionados == null || this.itemsNuevaTablaFac.length < 1) {
        this.setAlert({
          type: "warning",
          message:
            "Debe seleccionar al menos un registro de la tabla para poder actualizar el importe"
        });
        this.loadingBtn = false;
      } else if (existenRepetidos) {
        const duplicado = this.registroSelected.find(x =>
          this.registroSelected.find(
            y => y.nuevoNombre === x.nuevoNombre && x.tablaId != y.tablaId
          )
        );
        this.setAlert({
          type: "warning",
          message: `El nombre de la tabla ${duplicado.nuevoNombre} se encuentra repetido` //`El nuevo nombre de tabla ${duplicado.nuevoNombre} ya se encuentra en otro registro`
        });
        this.loadingBtn = false;
      } else {
        const objFilter = {
          agenteId: this.agenteSelected?.id
            ? parseInt(this.agenteSelected?.id)
            : null,
          oriId:
            this.origenSelected?.id != null ? this.origenSelected?.id : null,
          vigencia: this.parseDateToIso(this.fechaVigenciaSelected),
          nombreTabla: this.nombreTabla,
          prestador: this.prestadoresSelected
        };
        const stringObj = JSON.stringify(objFilter);
        const data = {
          porcentajeImporteFijo: parseFloat(this.porcentajeImporte),
          porcentajeUnidades: parseFloat(this.porcentajeUnidades),
          nuevaVigencia: this.parseDateToIso(this.fechaSelectedDesde),
          filtrosAplicados: stringObj,
          redondeo: this.tipoRedondeoSelected.id,
          // armar nuevo objeto en base id y nombre nuevo
          tablas: this.registroSelected.map(x => ({
            Id: x.tablaId,
            Value: x.nuevoNombre
          }))
        };
        try {
          const res = await this.saveProcesoAumentoTablasFacturacion(data);
          if (res.status === 200 && res.data.data.procesoOk) {
            this.setAlert({
              type: "success",
              message: "Actualización aplicada con éxito"
            });
            this.applyFilters();
            this.cleanCamposActualizacion();
            this.$router.push({
              name: "ActualizacionTablasFacturacion",
              params: { fromCreate: true }
            });
          } else if (!res.data.data.procesoOk) {
            this.tablasErrores = res.data.data.tablasError;
            this.openConfirmDialogExcelError = true;
          }
          this.loadingBtn = false;
        } catch {
          this.loadingBtn = false;
        }
      }
    },
    exportarExcelError() {
      let result = [];
      this.tablasErrores?.forEach(x =>
        result.push({
          ["Id tabla"]: x.id,
          ["Nombre tabla"]: x.value
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(formato, "Tablas de facturacion con errores");
    },
    prestadorChange(data) {
      this.prestadoresSelected = data;
    },
    customFilterOrigen(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    customFilterPrestadores(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    customFilterAgente(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    origenChange(data) {
      this.origenSelected = data;
    },
    openModal(item) {
      this.editNuevoNombre = item.nuevoNombre;
      this.idToEdit = item.tablaId;
      this.openModalEdit = true;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsNuevaTablaFac?.forEach(x =>
        result.push({
          ["Nombre tabla"]: x.tablaNombre,
          ["Observaciones"]: x.observaciones,
          ["Nuevo nombre tabla"]: x.nuevoNombre
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Actualización de tablas de facturación"
      );
    },
    getPrestadoresFilter() {
      if (this.timerPrestadores) {
        clearTimeout(this.timerPrestadores);
        this.timerPrestadores = null;
      }
      this.timerPrestadores = setTimeout(async () => {
        if (
          this.prestadores?.some(
            x => x.value == this.prestadoresAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaPrestadorselected) {
          this.prestadoresLoading = true;
          const response = await this.fetchPrestadoresByQuery({
            input: this.prestadoresAutocompleteInput
          });
          this.$refs["prestadores-autocomplete"].cachedItems = [];
          this.prestadores = response;
          this.prestadoresLoading = false;
        } else {
          this.prestadoresLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchPrestadoresByQuery({
            input: this.infoNuevaActualizacion.preId.toString()
          });
          this.prestadores = response;
          this.prestadoresSelected = response[0].id;
          this.banderaPrestadorselected = false;
          this.prestadoresLoading = false;
        }
      }, 1000);
    },
    getAgentesFilter() {
      if (this.timerAgentes) {
        clearTimeout(this.timerAgentes);
        this.timerAgentes = null;
      }
      this.timerAgentes = setTimeout(async () => {
        if (this.agentes?.some(x => x.value == this.agentesAutocompleteInput)) {
          return;
        } else if (!this.banderaAgentesSelected) {
          this.agentesLoading = true;
          const response = await this.getAgentePagoByQuery({
            input: this.agentesAutocompleteInput
          });
          this.$refs["agente-autocomplete"].cachedItems = [];
          this.agentes = response;
          this.agentesLoading = false;
        } else {
          this.agentesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.getAgentePagoByQuery({
            input: this.infoNuevaActualizacion.ageId.toString()
          });
          this.agentes = response;
          this.agenteSelected = response[0].id;
          this.banderaAgentesSelected = false;
          this.agentesLoading = false;
        }
      }, 1000);
    },
    getOrigenesFilter() {
      if (this.timerOrigenes) {
        clearTimeout(this.timerOrigenes);
        this.timerOrigenes = null;
      }
      this.timerOrigenes = setTimeout(async () => {
        if (
          this.origenes?.some(x => x.value == this.origenesAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.origenes = response;
          this.origenesLoading = false;
        } else {
          this.origenesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.infoNuevaActualizacion.oriNom.toString()
          });
          this.origenes = response;
          this.origenSelected = response[0].id;
          this.banderaOrigenSelected = false;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    saveEditValor() {
      let tablaFac = this.itemsNuevaTablaFac.find(
        x => x.tablaId === this.idToEdit
      );
      tablaFac.nuevoNombre = this.editNuevoNombre;
      this.openModalEdit = false;
    },

    selectAllToggle() {
      if (this.registroSelected.length != this.itemsNuevaTablaFac.length) {
        this.registroSelected = [];
        // Agrego una validacion particular de esta pantalla para que no me seleccione los items no validos
        this.registroSelected = this.itemsNuevaTablaFac.filter(
          x => x.tablaId > 0
        );
        this.setAlert({
          type: "info",
          message: "Todos los registros han sido seleccionados."
        });
      } else {
        this.registroSelected = [];
        this.setAlert({
          type: "info",
          message: "Todos los registros han sido deseleccionados."
        });
      }
    },
    cleanCamposActualizacion() {
      this.porcentajeImporte = null;
      this.porcentajeUnidades = null;
      this.fechaSelectedDesde = null;
      this.newSuffixTabla = "";
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.agentesAutocompleteInput) {
        this.filtersApplied.splice(0, 1, {
          key: "agentesAutocompleteInput",
          label: "Agente de cuenta",
          model: this.agentesAutocompleteInput
        });
      }
      if (this.origenesAutocompleteInput) {
        this.filtersApplied.splice(1, 1, {
          key: "origenesAutocompleteInput",
          label: "Orígen",
          model: this.origenesAutocompleteInput
        });
      }
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaVigenciaSelected",
          label: "Fecha de vigencia",
          model: this.fechaVigenciaSelected
        });
      }
      if (this.nombreTabla) {
        this.filtersApplied.splice(3, 1, {
          key: "nombreTabla",
          label: "Nombre de la tabla",
          model: this.nombreTabla
        });
      }
      if (this.prestadoresAutocompleteInput) {
        this.filtersApplied.splice(4, 1, {
          key: "prestadoresAutocompleteInput",
          label: "Prestador",
          model: this.prestadoresAutocompleteInput
        });
      }
    },
    resetForm() {
      this.agenteSelected = null;
      this.origenSelected = null;
      this.fechaVigenciaSelected = null;
      this.nombreTabla = null;
      this.prestadoresSelected = null;
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    verProceso(item, ctaId) {
      this.modalVerProceso = true;
      this.tablaId = item.tablaId;
      this.tablaNombre = item.tablaNombre;
      this.ctaId = ctaId;
    },
    goToConfiguracionTablasFacturacion() {
      this.$router.push({
        name: "ConfiguracionTablasFacturacion"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
